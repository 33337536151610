<template>
  <div class="contas">
    <h1 class="titulo-card fs-4">Contas bancárias</h1>
    <div class="form-group card-principal">
      <div class="d-flex justify-content-between">
        <div class="input-group mb-3">
          <button v-on:click="handleAdicionar()" class="btn btn-success">
            <font-awesome-icon :icon="['fas', 'plus']" fixed-width class="me-1"/>Adicionar
          </button>
        </div>

        <div class="input-group mb-3">
          <input
              v-on:keyup.enter="handlePesquisa()"
              type="text"
              class="form-control"
              placeholder="Pesquisar"
              aria-label="Pesquisar"
              aria-describedby="button-addon2">
          <button v-on:click="handlePesquisa()" class="btn btn-outline-secondary" type="button" id="button-addon2">
            <font-awesome-icon :icon="['fas', 'search']" fixed-width class="me-1"/>
          </button>
        </div>
      </div>
      <div class="d-flex">
        <table class="table table-sm-pc table-striped table-hover">
          <thead>
            <tr>
              <th width="120">Tipo</th>
              <th>Descrição</th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="conta in listaContas" :key="conta.id" @click="handleEditaConta(conta)" :class="cssAccount(conta)" class="cursor-pointer">
                <td scope="row">{{conta.type}}</td>
                <td>{{conta.description}}</td>
              </tr>
            </tbody>
        </table>
      </div>
      <Paginador v-model="paginador" @change-paginacao="onChangePaginacao"/>
    </div>
  </div>
</template>

<script>
import Paginador from '@/components/Paginador.vue'
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'
import clone from '@/modules/clone'
import model from '@/model'

export default {
  name: 'Banks',
  components: {
    Paginador,
  },
  data() {
    return {
      /** @type {model.paginador} */
      paginador: clone(model.paginador),
      listaContas: [],
    }
  },
  methods: {
    handlePesquisa() {
      loading(true);

      api.get(`/banks?${this.paginador.requestParams}&orderBy[description]`).then(res => {
        loading(false);
        this.listaContas = res.data.items;
        this.paginador.pagina = res.data.page;
        this.paginador.totalItens = res.data.totalItems;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    onChangePaginacao() {
      this.handlePesquisa();
    },

    handleAdicionar() {
      this.$router.push(`/configuracoes/contas-bancarias/novo`);
    },

    async handleEditaConta(conta) {
      this.$router.push(`/configuracoes/contas-bancarias/${conta.id}/editar`);
    },

    cssAccount(account) {
      if (account.active) {
        return 'text-info';
      }

      return 'text-muted-2';
    }
  },
  created() {
    this.handlePesquisa();
  }
}
</script>
